import { useCallback, useContext } from "react";
import { NavDropdown } from "react-bootstrap";

import { GoogleSignInButton } from "components/GoogleSignInButton";
import { Icon } from "components/Icon";
import { supabase } from "lib/supabase";
import { SessionContext } from "stores/SessionContext";

export const AuthNavStatus: React.FC = () => {
  const { session, setSession } = useContext(SessionContext);

  // Callback to sign out the user
  const signOut = useCallback(() => {
    void supabase.auth.signOut().then(() => {
      setSession(null);
    });
  }, []);

  // Show a sign in button if the user is not signed in
  if (!session) return <GoogleSignInButton />;

  // Otherwise show the auth dropdown
  return (
    <NavDropdown title={<Icon icon="person-circle" />} align={"end"}>
      <NavDropdown.ItemText className="d-flex align-items-center">
        <Icon icon="google" className="me-2" />
        {session.user.email}
      </NavDropdown.ItemText>
      <NavDropdown.Item className="d-flex align-items-center" onClick={signOut}>
        <Icon icon="box-arrow-left" className="me-2" />
        Sign Out
      </NavDropdown.Item>
    </NavDropdown>
  );
};
