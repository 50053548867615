import { AppRouter } from "app/AppRouter";
import { ErrorBoundary } from "components/ErrorBoundary";
import { SessionProvider } from "stores/SessionContext";

export function App() {
  return (
    <ErrorBoundary>
      <SessionProvider>
        <AppRouter />
      </SessionProvider>
    </ErrorBoundary>
  );
}
