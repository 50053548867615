import * as Sentry from "@sentry/react";
import React, { useCallback, useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

type FallbackProps = Parameters<Sentry.FallbackRender>[0];

const ErrorFallback: React.FC<FallbackProps> = ({ error, componentStack }) => {
  const [showComponentStack, setShowComponentStack] = useState(false);
  const toggleShowComponentStack = useCallback(() => {
    setShowComponentStack(!showComponentStack);
  }, [showComponentStack]);

  return (
    <div className="vw-100 vh-100">
      <Alert variant="danger" className="position-absolute top-50 start-50 translate-middle">
        <Alert.Heading>An unexpected error has occurred</Alert.Heading>
        <blockquote className="blockquote">{error instanceof Error ? error.message : "Unknown Error"}</blockquote>
        <p>You may refresh the page to reload the application.</p>
        <hr />
        <Button variant="secondary" onClick={toggleShowComponentStack}>
          {showComponentStack ? "Hide" : "Show"} Component Stack
        </Button>
        <Collapse in={showComponentStack} className="mt-3">
          <div className="border border-danger p-2">
            <pre className="m-0">
              <code>{componentStack.trim()}</code>
            </pre>
          </div>
        </Collapse>
      </Alert>
    </div>
  );
};

export const ErrorBoundary = (props: React.PropsWithChildren) => (
  <Sentry.ErrorBoundary showDialog={true} fallback={(props) => <ErrorFallback {...props} />}>
    {props.children}
  </Sentry.ErrorBoundary>
);
