import React, { useCallback, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";

import { Icon } from "components/Icon";

export interface ThemeSwitcherProps {
  className: string;
}

type ThemeOption = "auto" | ThemeName;

type ThemeName = "light" | "dark";

const themeIconMap = {
  light: "sun-fill",
  dark: "moon-stars-fill",
  auto: "circle-half",
};

export const ThemeSwitcher: React.FC<ThemeSwitcherProps> = ({ className }) => {
  const [theme, setThemeState] = React.useState<ThemeOption>("auto");

  // Set the initial theme when the component mounts
  useEffect(() => {
    const initialTheme = getCurrentTheme();
    setThemeState(initialTheme);
    setThemeOnDocument(initialTheme);
  }, []);

  // Event handler to update the theme when the user clicks on a theme toggle
  const onThemeButtonClicked = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const newTheme = e.currentTarget.dataset["theme"] as ThemeOption;
    setThemeState(newTheme);
    setThemeOnDocument(newTheme);
    setStoredTheme(newTheme);
  }, []);

  // Event handler to update the theme when the user changes their system's color scheme, but only if the theme is set to 'auto'
  const onPrefersColorSchemeChange = useCallback(() => {
    if (theme === "auto") setThemeOnDocument(getAutoTheme());
  }, [theme]);

  // Register event handlers when the component mounts
  useEffect(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    matchMedia.addEventListener("change", onPrefersColorSchemeChange);
    return () => {
      matchMedia.removeEventListener("change", onPrefersColorSchemeChange);
    };
  });

  return (
    <NavDropdown className={className} title={<ThemeDropdownTitle theme={theme} />} align={"end"}>
      {Object.entries(themeIconMap).map(([themeOption, icon]) => (
        <NavDropdown.Item
          key={themeOption}
          className={"d-flex align-items-center" + (themeOption === theme ? " active" : "")}
          onClick={onThemeButtonClicked}
          data-theme={themeOption}
        >
          <Icon icon={icon} className="me-2 opacity-50" />
          {themeOption}
          {themeOption === theme ?
            <Icon icon="check2" className="ms-auto" />
          : null}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

const ThemeDropdownTitle: React.FC<{ theme: ThemeOption }> = ({ theme }) => {
  return (
    <>
      <Icon icon={themeIconMap[theme]} />
      <span className="d-lg-none ms-2">Toggle theme</span>
    </>
  );
};

function getStoredTheme(): ThemeOption | null {
  return localStorage.getItem("theme") as ThemeOption | null;
}

function setStoredTheme(theme: ThemeOption) {
  localStorage.setItem("theme", theme);
}

/**
 * Get the theme the user selected manually (and stored in local storage), defaulting to 'auto' if none is set.
 */
function getCurrentTheme(): ThemeOption {
  const storedTheme = getStoredTheme();
  if (storedTheme) return storedTheme;
  return "auto";
}

/** Gets the theme to use when set to 'auto' mode. */
function getAutoTheme(): ThemeName {
  return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
}

/**
 * Set the theme for the entire document using the [data-bs-theme] attribute on the <html> element.
 */
function setThemeOnDocument(theme: ThemeOption) {
  const docElement = document.documentElement;
  if (theme === "auto") theme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  docElement.setAttribute("data-bs-theme", theme);
}
