import { Container } from "react-bootstrap";
import { Outlet } from "react-router";

import { TopNav } from "app/TopNav";

export const Layout: React.FC = () => {
  return (
    <>
      <TopNav />
      <Container>
        <Outlet />
      </Container>
    </>
  );
};
