import { useContext } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

import { AuthNavStatus } from "components/AuthNavStatus";
import LinkContainer from "components/LinkContainer";
import { Logo } from "components/Logo";
import { ThemeSwitcher } from "components/ThemeSwitcher";
import { SessionContext } from "stores/SessionContext";

export const TopNav: React.FC = () => {
  const { session } = useContext(SessionContext);
  const salesOrders = ["0152", "0153", "0154"];

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <Logo /> Senti Order Tracker
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {session && (
              <NavDropdown title="Sales Orders" id="basic-nav-dropdown">
                {salesOrders.map((so) => (
                  <LinkContainer key={so} to={`/so/${so}`}>
                    <NavDropdown.Item>SO-{so}</NavDropdown.Item>
                  </LinkContainer>
                ))}
              </NavDropdown>
            )}
          </Nav>
          <Nav className="ms-md-auto">
            <ThemeSwitcher className="me-1" /> <AuthNavStatus />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
