import React, { CSSProperties, MouseEventHandler, ReactElement } from "react";
import { Location, PathMatch, To, useHref, useLocation, useMatch, useNavigate } from "react-router";

/**
 * NOTE: The contents of this file were originally copied from `react-router-bootstrap`. They have been modified to use
 * native TypeScript and work with react router V7
 */

export interface LinkContainerProps {
  children: ReactElement<ChildProps>;
  onClick?: MouseEventHandler;
  replace?: boolean;
  to: string | To;
  state?: unknown;
  className?: string;
  activeClassName?: string;
  style?: CSSProperties;
  activeStyle?: CSSProperties;
  isActive?: boolean | IsActiveCallback;
}

/** The child element must be one that supports all these props */
export interface ChildProps {
  onClick?: MouseEventHandler;
  className?: string;
  style?: CSSProperties;
  href?: string;
}

export type IsActiveCallback = (match: PathMatch<"*"> | null, location: Location<unknown>) => boolean;

const LinkContainer: React.FC<LinkContainerProps> = ({
  children,
  onClick,
  replace = false,
  to,
  state,
  activeClassName = "active",
  className,
  activeStyle,
  style,
  isActive: getIsActive,
  ...props
}) => {
  const path = typeof to === "object" ? (to.pathname ?? "") : to;
  const navigate = useNavigate();
  const href = useHref(typeof to === "string" ? { pathname: to } : to);
  const match = useMatch(path);
  const location = useLocation();
  const child = React.Children.only(children);

  const isActive =
    !getIsActive ? !!useMatch(path)
    : typeof getIsActive === "function" ? getIsActive(match, location)
    : getIsActive;

  const handleClick: MouseEventHandler = (event) => {
    // Call event handlers provided via onClick props
    if (children.props.onClick) children.props.onClick(event);
    if (onClick) onClick(event);

    // Do not process the event further under certain conditions
    if (
      event.defaultPrevented || // onClick handler above prevented default
      event.button !== 0 || // is right click
      isModifiedEvent(event) // is click with a modifier key
    ) {
      return;
    }

    // Prevent default browser click behavior from the element and instead navigate programmatically using react-router
    event.preventDefault();
    void navigate(to, { replace, state });
  };

  // Return a clone of the child element with relevant props modified
  return React.cloneElement(child, {
    ...props,
    className: [className, child.props.className, isActive ? activeClassName : null].join(" ").trim(),
    style: isActive ? { ...style, ...activeStyle } : style,
    href,
    onClick: handleClick,
  });
};

const isModifiedEvent = (event: React.MouseEvent) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export default LinkContainer;
