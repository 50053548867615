export interface IconProps extends React.SVGProps<SVGSVGElement> {
  icon: string;
}

const spriteSheetUrl = new URL("npm:bootstrap-icons/bootstrap-icons.svg", import.meta.url);

export const Icon: React.FC<IconProps> = ({ icon, className, ...props }) => {
  return (
    <svg className={"bi " + (className ?? "")} fill="currentColor" {...props}>
      <use href={spriteSheetUrl.href + "#" + icon} />
    </svg>
  );
};
