import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router";

import { SessionContext } from "stores/SessionContext";

interface ProtectedRouteProps {
  children?: React.ReactNode[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { loading, session } = useContext(SessionContext);
  const location = useLocation();

  // Render nothing while the existing session state is loading
  // This is necessary to prevent doing an unnecessary redirect
  if (loading) return null;

  if (!session) {
    const returnUrl = encodeURIComponent(location.pathname + location.search + location.hash);
    console.info("Redirecting to 'unauthenticated' page with return URL", returnUrl);
    return <Navigate to={"/unauthenticated?returnUrl=" + returnUrl} replace />;
  }
  return children ? children : <Outlet />;
};
