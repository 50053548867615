import { useEffect } from "react";

import { supabase } from "lib/supabase";
import { CredentialResponse } from "google-one-tap";

declare global {
  /** Callback executed when the Google Sign-In process completes. */
  function handleSignInWithGoogle(googleAuthResponse: CredentialResponse): Promise<void>;
}

// Register a method on the global scope to handle Google Sign-In responses
// TODO: Create a nonce for Google authentication. See: https://supabase.com/docs/guides/auth/social-login/auth-google#google-pre-built
globalThis.handleSignInWithGoogle = async (googleAuthResponse) => {
  // Use the token returned from Google to authenticate with Supabase
  // NOTE: This will raise the `supabase.auth.onAuthStateChange` event to any listeners (i.e. the AuthNavStatus component)
  const supabaseAuthResponse = await supabase.auth.signInWithIdToken({
    provider: "google",
    token: googleAuthResponse.credential,
  });

  // Throw an error if the authentication request failed
  if (supabaseAuthResponse.error) throw supabaseAuthResponse.error;
};

export const GoogleSignInButton: React.FC = () => {
  // Inject the Google Sign-In script into the DOM every time this component is rendered
  // This is necessary because the script only runs once, and we need it to run every time the button is added to the DOM
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        id="g_id_onload"
        data-client_id={process.env.GOOGLE_OAUTH_CLIENT_ID}
        data-context="signin"
        data-ux_mode="popup"
        data-callback="handleSignInWithGoogle" // NOTE: This must match the method name registered on the global scope
        data-auto_select="true"
        data-itp_support="true"
      ></div>
      <div
        className="g_id_signin overflow-hidden"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="signin_with"
        data-size="large"
        data-logo_alignment="left"
      ></div>
    </>
  );
};
