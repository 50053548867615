import { Session } from "@supabase/supabase-js";
import { supabase } from "lib/supabase";
import { createContext, ReactNode, useEffect, useState } from "react";

export interface SessionContext {
  loading: boolean;
  session: Session | null;
  setSession: (session: Session | null) => void;
}

export const SessionContext = createContext<SessionContext>(null as unknown as SessionContext);

export const SessionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<Session | null>(null);

  // Manage the session state
  useEffect(() => {
    // Load the initial session state
    void supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    // Listen for changes to the session state
    const stateChangeEventHandler = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    // Unsubscribe from the event when the component is unmounted
    return stateChangeEventHandler.data.subscription.unsubscribe;
  }, []);

  return <SessionContext.Provider value={{ loading, session, setSession }}>{children}</SessionContext.Provider>;
};
