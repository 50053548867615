import { BrowserRouter, Route, Routes } from "react-router";

import { Layout } from "app/Layout";
import { Home } from "app/pages/Home";
import { SalesOrder } from "app/pages/SalesOrder";
import { Unauthenticated } from "app/pages/Unauthenticated";
import { ProtectedRoute } from "components/ProtectedRoute";

export const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Layout />}>
        <Route element={<ProtectedRoute />}>
          <Route index element={<Home />} />
          <Route path="so/:soId" element={<SalesOrder />} />
        </Route>
        <Route path="unauthenticated" element={<Unauthenticated />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
