import { useContext } from "react";
import { Navigate, useSearchParams } from "react-router";
import { SessionContext } from "stores/SessionContext";

/**
 * Displays a message to the user when they are not authenticated indicating that they should log in. The message
 * is centered on the page.
 */
export const Unauthenticated: React.FC = () => {
  const { session } = useContext(SessionContext);
  const [searchParams] = useSearchParams();

  // Navigate back to the previous page automatically when the user signs in
  if (session) {
    const returnUrl = searchParams.get("returnUrl") || "/";
    console.info("User is now authenticated, redirecting to the return URL", returnUrl);
    return <Navigate to={returnUrl} replace />;
  }

  // Show the unauthenticated message
  return (
    <div className="text-center mt-5">
      <h1>You are not authenticated</h1>
      <p>Please log in using the Google Sign In button at the top right to continue.</p>
    </div>
  );
};
