// NOTE: Style for this SVG are applied via an external stylesheet because we do not have `unsafe-inline` CSP enabled
//       The SVG must be inlined in the DOM so that we can apply conditional styles to it based on the current theme.
export const Logo = () => (
  <svg id="senti-logo" width={30} height={30} x="0" y="0" viewBox="0 0 360 262.57">
    <path
      className="st2"
      d="M135.71 144.41c-.33 1.61-.88 3.78-3.01 7.13-3.91 6.14-12.09 13.09-29.07 13.09-30.41 0-30.27-22.3-63.89-22.3-25.34 0-28.61 12.67-28.68 18.91"
    />
    <path
      className="st1"
      d="M178.9 29.64c28.28 22.28 46.79 59.33 46.79 101.27 0 42.01-18.57 79.12-46.93 101.38m34.23-41.1h126.97m-114.75-35.62h127.3M221.35 85.72h127.3m-146.59-28.8h127.3"
    />
    <ellipse className="st1" cx="155.29" cy="130.91" rx="108.98" ry="123.31" />
    <ellipse className="st1" cx="201.58" cy="130.91" rx="108.98" ry="123.31" />
    <path
      className="st1"
      d="M135.71 144.77c-.45-4.55-.68-9.18-.68-13.86 0-68.1 48.79-123.31 108.98-123.31s108.98 55.21 108.98 123.31-48.79 123.31-108.98 123.31c-56.04 0-102.21-47.87-108.3-109.45m12.52 46.42H20.92m150.45 30.47H44.07m90.96-101.71H7.73m131.16-34.23H11.59m146.59-28.8H30.87M192.9 221.66h127.3m-96.44-101.71h127.31M11.05 161.24c-2.17-9.7-3.32-19.87-3.32-30.34C7.73 62.81 56.52 7.6 116.71 7.6c13.58 0 26.59 2.81 38.58 7.95m2.31 229.69c-12.63 5.79-26.43 8.98-40.89 8.98-50.94 0-93.71-39.54-105.66-92.97M50.1 33.58h260.46"
    />
  </svg>
);
